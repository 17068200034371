.farmers{
    width: 100%;
    min-width: 414px;
    min-height: calc(100vh - 60px);
    background-color: var(--bg2);
    color: var(--text);
}

.search_filter_cont{
    width: 100%;
    padding: 20px;
    background-color: var(--bg1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search_cont{
    width: 100%;
    padding: 10px;
    background-color: var(--bg2);
    display: flex;
    align-items: center;
    border-radius: 15px;
}

.search_icon{
    font-size: 20px;
    margin-right: 10px;
}

.search_cont > input{
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    color: var(--text);
}

.filter_cont{
    width: 100%;
    padding: 10px;
}

.dropdown{
    outline: none;
    border: none;
    width: 100%;
    cursor: pointer;
    font-size: 20px;
    background: transparent;
    color: var(--text);
    width: 100%;
}

.dropdown > option{
    padding: 10px !important;
    background: var(--bg1);
    margin-top: 10px;
}


.add_farmer_cont{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    display: flex;
    justify-content: flex-end;

}

.add_farmer_cont h4{
    color: var(--white);
    border-radius: 15px;
    padding: 15px 20px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    color: var(--primeCol);
}


.add_farmer_cont h4:hover{
    background-color: var(--primeCol10);
}

.list_farmers_cont{
    width: 100%;
    margin-top: 20px;
    background-color: var(--bg1);
    padding: 20px;
    flex-wrap: wrap;
    position: relative;
    overflow-x: scroll;
    overflow: hidden;
}

.data_loadmore_btn{
    width: 100%;
    padding: 20px;
    display: grid;
    place-items: center;
}

.data_loadmore_btn > h4{
    cursor: pointer;
    font-weight: normal;
    font-size: 20px;
}

.data_loadmore_btn > h4:hover{
    color: var(--primeCol);
}




@media (min-width : 740px) {
    .search_filter_cont{
        margin: auto;
    }

    .farmers{
        padding: 20px;
    }
    .search_filter_cont{
        flex-direction: row;
        justify-content: space-between;
        border-radius: 20px;
        max-width: 1200px;
    }

    .filter_cont{
        max-width: 400px;
        display: flex;
        justify-content: flex-end;
    }

    .filter_cont > select{
        max-width: 200px;
        margin-right: 15px;
    }

    .search_cont{
        width: 300px;
    }

    .list_farmers_cont{
        border-radius: 20px;
        margin: 20px auto;
        max-width: 1200px;
    }
}