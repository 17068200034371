.clients{
    background-color: var(--bg2);
    color: var(--text);
    padding: 20px 0;
    min-height: calc(100vh - 60px);
}


.search_cont{
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    max-width: 1200px;
    margin: auto;
}


.search_field{
    display: flex;
    align-items: center;
    background-color: var(--bg2);
    padding: 0px 15px;
    border-radius: 15px;
    font-size: 20px;
    width: 100%;
}

.search_field input{
    border: none;
    outline: none;
    padding: 10px 15px;
    background: transparent;
}

.add_client_cont{
    width: 100%;
    max-width: 1200px;
    padding: 15px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}

.add_client_btn{
    color: var(--primeCol);
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    width: fit-content;
}

.add_client_btn:hover{
    background-color: var(--primeCol10);
}




@media (min-width : 740px) {
    .clients{
        padding: 20px;
    }
}