.settings{
    width: 100%;
    min-height: calc(100vh - 50px);
    background-color: var(--bg2);
    color: var(--text);
    padding: 20px 0;
}

.theme_cont,.credential_update_cont{
    width: 100%;
    max-width: 1200px;
    border-radius: 20px;
    padding: 20px;
    background-color: var(--bg1);
    margin: auto;
    margin-top: 20px;
    
}
.title_cont{
    width: 100%;
    border-bottom: 0.1px solid var(--text);
    padding: 10px;
}

.title_cont > h4{
    color: var(--text);
}

.body_cont{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-top: 10px;
}
.body_cont > h3{
    font-weight: normal;
}

.body_cont form{
    width: 100%;
}


.input_cont{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--bg2);
    margin: 15px 0;
    border-radius: 15px;
}

.input_icon_cont{
    margin-right: 10px;
}
.input_icon{
    font-size: 24px;
}

.input_cont > input{
    flex: 1;
    padding: 10px;
    border: none;
    outline: none;
    background: transparent;
    color: var(--text);
}

form  button{
    width: 100%;
    border: none;
    outline: none;
    background-color: var(--primeCol);
    color: var(--white);
    padding: 14px 16px;
    border-radius: 15px;
    height: 50px;
    cursor: pointer;
    margin-top: 15px;
}


.theme_cont .dark_mode_indicator{
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background-color: var(--bg1);
    position: absolute;
    left: 5px;
    right: 1px;
    transition: ease 0.3s;
    
}

.theme_cont .dark_mode_indicator_active{
    left: 35px;
    right: 1px;
    transition: ease 0.3s;
}


@media (min-width : 740px) {
    .settings{
        padding: 20px;
    }
}