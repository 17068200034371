.farmer_add {
  width: 100%;
  background-color: var(--bg2);
  min-height: calc(100vh - 60px);
  color: var(--text);
}

.form_cont {
  width: 100%;
  margin: auto;
}

.form_inner {
  width: 100%;
  margin: auto;
  background-color: var(--bg1);
  padding: 20px;
}

.form_input {
  margin-top: 15px;
}

.form_input h3 {
  font-weight: normal;
  color: #a7a6a6;
}

.form_input input,
.form_input select {
  border: none;
  outline: none;

  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 10px;
  font-size: 18px;
  background-color: var(--bg2);
  color: var(--text);
}

.form_submit_cont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  /* padding: 20px 0; */
}

.form_submit_cont button {
  width: 80%;
  border: none;
  outline: none;
  padding: 15px;
  background-color: var(--bg3);
  color: var(--white);
  border-radius: 10px;
  font-size: 15px;
}

@media (min-width: 768px) {
  .form_cont {
    padding: 10px 15px;
  }
  .form_inner {
    max-width: 1200px;
    border-radius: 20px;
    padding: 30px;
  }

  .form_inner > .input_div {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .form_submit_cont {
    width: 250px;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    padding: 20px 0;
  }

  .form_submit_cont button {
    width: 100%;
    border: none;
    outline: none;
    padding: 15px;
    background-color: var(--bg3);
    color: var(--white);
    border-radius: 10px;
    font-size: 15px;
  }
}
