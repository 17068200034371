.farmer_transactions{
    width: 100%;
    color: var(--text);
    background-color: var(--bg2);
    padding: 30px;
}

.farmer_transactions_inner{
    width: 100%;
    max-width: 1200px;
    margin: auto;
}


.transactions_title,.farmer_name{
    width: 100%;
    padding: 15px 0;
    font-weight: bold;
    font-size: 24px;
    
}

.farmer_name{
    font-weight: normal;
    font-size: 18px;
}

.type_transaction_indicators_cont,
.type_transaction_indicators{
    width: 100%;
    padding: 10px 15px;
    display: flex;
    justify-content: flex-end;
}
.type_transaction_indicators{
    width: auto;
}

.type_transaction_indicator{
    
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.type_transaction_indicator > .indicator{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: green;
}

.type_transaction_indicator > h4{
    font-weight: normal;
}

.type_transaction_indicator > .green{
    background-color: green;
}
.type_transaction_indicator > .violet{
    background-color: violet;
}
.type_transaction_indicator > .yellow{
    background-color: yellow;
}


.transaction_search{
    width: 100%;
    max-width: 1200px;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg1);
}

.transaction_search_field{
    width: 90%;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: var(--primeColLight);
    border-radius: 15px;
    font-size: 24px;
}

.transaction_search_field > input{
    flex: 1;
    padding: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 20px;
}


.list_transactions{
    width: 100%;
    background-color: var(--bg1);
    margin-top: 30px;
    border-radius: 20px;
    padding: 20px;
}


.transaction_tile{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 30px;
    border-radius: 20px;
    background-color: var(--bg4);
    margin-top: 15px;
}

.transaction_tile > h4{
    font-weight: normal;
    font-size: 19px;
}




