.field_agent_dashboard{
    min-height: 100%;
    background-color: var(--bg2);
    padding: 20px;
    color: var(--text);

}

.field_agent_dashboard_inner{
    max-width: 1200px;
    margin: auto;
}


.field_agent_profile{
    width: 100%;
    min-width: 600px;
    max-width: 600px;
    background-color: var(--bg1);
    padding: 20px;
    margin: auto;
}




.field_agent_profile > .top{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
}

.field_agent_profile > .top > .field_agent_image{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: var(--bg2);
    margin-right: 15px;
    overflow: hidden;
}

.field_agent_image > .field_agent_pic{
    width: 100%;
    height: 100%;
}


.field_agent_position > h3{
    display: flex;
    align-items: center;
}

.field_agent_position > h3 > span{
    color: var(--primeCol);
}


.field_agent_profile > .bottom{
    width: 100%;
    padding: 10px;
}

.field_agent_profile > .bottom > div{
    padding: 10px;
    width: 100%;
}

.field_agent_profile > .bottom > div > h4{
    font-size: 22px;
}

.field_agent_profile > .bottom > div > h4 > span{
    font-weight: normal;
    font-size: 20px;
}



@media (min-width: 740px) {
    .field_agent_profile{
        border-radius: 15px;
    }
}