.farmer_wallet{
    background-color: var(--bg2);
    width: 100%;
    min-height: calc(100vh - 60px);
    color: var(--text);
    padding: 10px;
}


.farmer_wallet_cont{
    width: 100%;
    max-width: 750px;
    min-height: 600px;
    margin: auto;
    background-color: var(--bg1);
    padding: 10px;
    border-radius: 20px;
    margin-top: 20px;
}

.farmer_wallet_cont > h4{
    font-size: 18px;
    font-weight: normal;
    padding: 10px;
    margin-top: 10px;
}

.farmer_wallet_cont > h2{
    padding: 10px;
    margin-top: 20px;
}

/* .farmer_wallet_cont > h2 > span{
    font-weight: normal;
} */

.farmer_wallet_title{
    opacity: 0.5;
}



.wallet_btn_cont{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.wallet_btn_cont > button{
    outline: none;
    border: none;
    background-color: var(--primeCol);
    color: var(--white);
    padding: 14px 16px;
    border-radius: 10px;
    width: 150px;
    font-size: 18px;
    cursor: pointer;
}



@media (min-width: 740px) {
    .farmer_wallet_cont{
        padding: 50px;
        margin-top: 0;
    }
}