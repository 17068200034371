table{
    width: 100%;
    border-collapse: collapse;


}

th{
    padding: 10px;
}

table img,
table .t_img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--bg3);
}

table th,
table tr.table-row td{
    padding: 30px 15px;
    outline: none;
    text-align: center;
    font-size: 20px;
    text-overflow: ellipsis;
    max-width: 200px;

    /* testing the elipses for the text */
    max-lines: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

table tr.table-row td{
    font-weight: normal;
    padding: 20px;

}

tr.table-row{
    background-color: var(--primeCol10);
    border-radius: 50px;
    align-items: center;
    cursor: pointer;
    border-bottom: 10px solid var(--bg1);
}

/* tr:nth-child(odd){
    background-color: var(--primeCol10);
} */



table tr.table-row:hover{
    background-color: var(--primeColLight);
    color: var(--white);
}

table thead tr.table-row:hover{
    color: var(--text);
}


table th:nth-child(4),
table th:nth-child(5),
table th:nth-child(6),

table tr.table-row > td:nth-child(4),
table tr.table-row > td:nth-child(5),
table tr.table-row > td:nth-child(6){
    display: none;
}


thead  tr.table-row{
    background-color: transparent;
}

thead tr.table-row:hover{
    background: transparent;
}

@media (min-width:740px) {


    table th:nth-child(4),
    table th:nth-child(5),
    table th:nth-child(6),

    table tr.table-row > td:nth-child(4),
    table tr.table-row > td:nth-child(5),
    table tr.table-row > td:nth-child(6){
        display: table-cell;
    }
}






















/* .farmers_table{
    width: 100%;
}

.table_head,
.table_tile{
    width: 100%;
}



.table_head{
    padding: 10px;
}



/* .table_body{
    padding-top: 10px;
    padding-bottom: 50px;
}

.table_tile{

    margin-top: 10px;
    background-color: var(--primeCol10);
    border-radius: 15px;
    align-items: center;
    cursor: pointer;
}

.table_tile:hover{
    background-color: var(--primeColLight);
    color: var(--white);
}

.table_tile > h4{
    font-weight: normal;
}

.table_tile img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
} */


/* .go_to_top{
    width: 100%;
    display: grid;
    place-items: center;
    padding: 100px 0;

}


 */


