/* .dashboards{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: red;

    min-width: 414px;
    min-height: calc(100vh - 60px);
    background-color: var(--bg2);
    color: var(--text);
} */

.dashboards {
  width: 100%;
  min-width: 414px;
  min-height: calc(100vh - 60px);
  background-color: var(--bg2);
  /* background-color: red; */
  color: var(--text);
}

.dashboards-cont {
  background-color: var(--bg1);
  min-height: calc(100vh - 60px);
}

/* Add this to your stylesheet or inline styles */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

@media (min-width: 740px) {
  .dashboards {
    padding: 20px;
  }
}
