.auth {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg1);
  color: var(--text);
}

.auth_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth_top img {
  height: 50px;
  width: 50px;
  border-radius: 10px;
}
.auth_top h1 {
  color: var(--primeCol);
  font-weight: bolder;
}

/* FORM STYLE */

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.auth_input_cont {
  display: flex;
  align-items: center;
  margin: 5px 0 15px;
  padding: 0 10px;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 5px;
}

.auth_input_cont:is(:hover, :focus) {
  border-color: var(--primeCol);
}

.auth_input_icon {
  font-size: 24px;
}

.auth_input_cont > input {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
  background: transparent;
  color: var(--text);
  margin: 0, 10px;
}

form input[type="checkbox"] {
  accent-color: var(--primeCol);
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.auth_bottom > form > button {
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--primeCol);
  color: var(--white);
  padding: 14px 16px;
  border-radius: 5px;
  height: 50px;
  cursor: pointer;
  margin-top: 40px;
}

@media (min-width: 740px) {
  .auth_input_cont {
    max-width: 350px;
  }

  .auth_bottom > form {
    max-width: 350px;
  }
}
