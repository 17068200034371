.leaflet-container{
    height: 100%;
}

.map_cont{
    width: 100%;
    /* height: calc(100vh - 60px); */
    height: 100%;
    background-color: var(--bg2);
}
