.navbar{
    background-color: var(--bg3);
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.navbar_inner{
    width: 100%;
    /* max-width: 1220px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.menu_n_logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.menu_n_logo > .menu_btn{
    padding: 10px;
    font-size: 24px;
    color: var(--white);
    cursor: pointer;
    display: grid;
    place-items: center;

    order: 2;
}


.logo_cont,.logo_cont > img{
    width: auto;
    height: 40px;
    cursor: pointer;
}


.navigations{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.navigation_tile{
    display: flex;
    align-items: center;
    color: var(--white);
    padding: 5px 10px;
    margin: 0px 10px;
    font-size: 20px;
    cursor: pointer;
    transition: ease 0.3s;
    text-decoration: none;
}

.navigation_tile > h4{
    font-weight: normal;
    margin-left: 0;
    font-size: 15px;
    transition: ease 0.3s;
}

/* NAVLINKS ACTIVE */

.navigation_tile_active , .navigation_tile:hover{
    background-color: var(--white10);
    border-radius: 50px;
    font-weight: bold;
    transition: ease 0.3s;
    
}

.navigation_tile_active > h4{
    margin-left: 5px;
    font-weight: 600;
    transition: ease 0.3s;
}



/* ACTIONS */

.actions{
    display: flex;
    align-items: center;

    display: none;
}

.logout_cont{
    color: var(--white);
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
}
.logout_cont:hover{
    background-color: var(--white10);
}


.dark_mode_toggle_cont{
    width: 60px;
    height: 25px;
    border-radius: 50px;
    background-color: var(--white10);
    margin-right: 10px;
    padding: 0 5px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.dark_mode_indicator{
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background-color: var(--bg1);
    position: absolute;
    left: 5px;
    right: 1px;
    transition: ease 0.3s;
    
}

.dark_mode_indicator_active{
    left: 35px;
    right: 1px;
    transition: ease 0.3s;
}





@media (min-width : 740px) {

    .actions{
        display: flex;
    }

    .menu_n_logo{
        width: auto;
    }

    .menu_btn{
        order: 1;
        margin-right: 20px;
    }

    .logo_cont{
        order: 2;
    }


    
}