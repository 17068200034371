.field_agents{
    min-height: 100%;
    background-color: var(--bg2);
    color: var(--text);
}

.field_agents_inner{
    width: 100%;
    max-width: 1200px;
    margin: auto;
}

.field_agents .table_tile,
.field_agents .table_head{
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px;
}


.field_agents .table_head > h4:last-child,
.field_agents .table_tile > h4:last-child{
    display: none;
}

.field_agents .table_title > h4:nth-child(2){
    display: block;
}

@media (min-width : 740px) {
    .field_agents{
        padding: 20px;
    }


    .field_agents .table_tile,
    .field_agents .table_head{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }


    .field_agents .table_head > h4:last-child,
    .field_agents .table_tile > h4:last-child{
        display: block;
    }
}