.warehouses{
    background-color: var(--bg2);
    color: var(--text);
    padding: 10px;
}


.warehouses .table_tile,
.warehouses .table_head{
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px;
}

.warehouses .search_filter_cont{
    border-radius: 10px;
}

.warehouses .filter_cont{
    margin-top: 10px;
}



@media (min-width: 740px) {
    .warehouses{
        padding: 20px;
    }
    
    .warehouses .search_filter_cont{
        border-radius: 20px;
    }

    .warehouses .filter_cont{
        margin-top: 0;
    }
}