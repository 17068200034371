.side_navbar{
    height: calc(100vh - 60px);
    background-color: var(--bg4);
    justify-content: flex-start;
    position: sticky;
    top: 60px;

    width: 0px !important;
    transition: ease 0.3s;
    overflow: hidden;
}

.side_navbar_active{
    width: 300px !important;
    transition: ease 0.3s;
}
.side_navbar, .side_navbar_inner{
    width: 100%;
    background-color: var(--bg3);

}

.side_navbar_inner{
    padding: 14px 16px;
    width: 300px;
    margin: auto;
    position: relative;
}

.side_navbar_tile{
    display: flex;
    width: 100%;
    max-width: 300px;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    color: var(--white);
    padding: 10px;
    text-decoration: none;
    margin-top: 10px;
}

.side_navbar_icon{
    font-size: 24px;
}
.side_navbar_tile_active{
    background-color: var(--primeColLight);
    border-radius: 10px;
}

.side_navbar_tile:hover{
    background-color: var(--primeColLight);
    border-radius: 10px;
    cursor: pointer;

}

.side_navbar_title{
    margin-left: 10px;
    font-weight: normal;
}


.side_actions{
    position: absolute;
    margin-top: 30px;
    width: 100%;
    margin-right: 10px;
    border-top: 1px solid var(--white10);
    padding: 10px;
    color: var(--white);
}

.side_actions > div{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-right: 30px;
}

.side_actions  .logout_cont{
    width: 70px;
    background-color: var(--white10);
    border-radius: 50px;
    display: grid;
    place-items: center;
}

@media (min-width : 740px) {

    .side_navbar_inner{
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: var(--bg3);
    }

    .side_navbar_tile{
        justify-content: flex-start;
        height: 50px;
    }

    .side_actions{
        display: none;
    }
}