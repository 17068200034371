
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Poppins', sans-serif;
  background-color: var(--bg2);
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;

  /* --primeCol : #0FAC9C;
  --primeColDeep : rgb(0,128,115);
  --lightPrimeCol :  #F8FBFA;
  --lightPrimeCol :  #0fac9c18; */

  --primeCol : #05897C;
  --primeColLight : #0FAC9C;
  --primeCol10 : #05897c21;

  --tableHead : hsla(0, 0%, 77%, 0.3);

  --white : white;
  --darkWhite : #F6F6F6;
  --white10 :rgba(255, 255, 255, 0.10);
  --white70 : rgba(255, 255, 255, 0.70);

  --black : rgb(15, 15, 15);
  --blackBright : rgb(39, 39, 39);
  --black10 : rgba(0, 0, 0, 0.10);
  --black70 : rgba(0, 0, 0, 0.70);


  /* second theme for dark mode */


  --lightGray : lightgray;
  --gray : rgb(109, 109, 109);
  
  --borderBlack : 1px solid  var(--black);
  --borderWhiteSmoke : whitesmoke;
  
  --boxShadow : 0 4px 8px 0 rgba(0, 0, 0, 0.11), 0 6px 20px 0 rgba(0, 0, 0, 0.062);
  --boxShadowLight : 0 4px 8px 0 rgba(0, 0, 0, 0.11), 0 6px 20px 0 rgba(41, 41, 41, 0.041);


  /* TEXT */
 --primeText : var(--primeCol);
 --text : var(--black);

 /* BACKGROUND */
 --bg1 : var(--white); 
 --bg2 : var(--darkWhite);
 --bg3 : var(--primeCol);
 --bg4 : var(--primeColLight)

 
 
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */



.dark{
  --text : var(--white);

  /* BACKGROUND  */
  --bg1 : var(--blackBright);
  --bg2 : var(--black);
  --bg3 : var(--blackBright);
  --bg4 : var(--blackBright);


  /* --bg1 : #041f1c;
  --bg2 : #020f0e;
  --bg3 : #041f1c;
  --bg4 : #041f1c; */
}


.btn{
  width: 100%;
  border: none;
  outline: none;
  padding: 15px;
  background-color: var(--bg3);
  color: var(--white);
  border-radius: 10px;
  font-size: 20px;
}


::-webkit-scrollbar{
  width: 10px;
  height: 10px;
  background-color: var(--bg1);
  
}
::-webkit-scrollbar-thumb{
  width: 10px;
  border-radius: 20px;
  background-color: var(--bg3);
  background-color: #05897c21;
  opacity: 0.3;
}


input, select{
  outline: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}