.sm_analysis_cont,
.sm_analysis_cont_inner{
    width: 100%;
    color: var(--text);
}

.sm_analysis_cont{
    margin-bottom: 50px;
}

.sm_analysis_top{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
}

.analysis_card{
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: var(--bg1);
    border-radius: 15px;
    
}


.sm_analysis_icon_cont{
    font-size: 30px;
    color: var(--primeCol);
}


.analysis_card h4{
    font-weight: normal;  
}


.analysis_card h2{
    font-size: 50px;
    font-weight: 600;
}



.sm_analysis_bottom{
    min-height: 200px;
    padding: 20px 0;
    position: relative;
    overflow: hidden;
}

.sm_analysis_graph_cont{
    padding: 30px;
    /* max-height: 400px; */
    position: relative;
}


.sm_analysis_graph_cont{
    width: 100%;
    min-height: 200px;
    border-radius: 15px;
    background-color: var(--bg1);
}

@media (min-width : 740px) {
    .sm_analysis_top{
        flex-direction: row;
    }
}

@media (min-width : 1200px) {
    .sm_analysis_cont_inner{
        max-width: 1200px;
        margin: auto;
    }


}