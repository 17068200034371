.farmer_dashboard{
    width: 100%;
    background-color: var(--bg2);
    min-height: calc(100vh - 60px);
    color: var(--text);
    padding-bottom: 30px;
}

.close_container,
.edit_btn_cont{
    width: 100%;
    padding: 10px;
    max-width: 1200px;
    margin: auto;
}

.close_btn{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--bg3);
    color: var(--white);
    display: grid;
    place-items: center;
    cursor: pointer;
}

.edit_btn_cont > button{
    outline: none;
    border: none;
    background-color: var(--bg3);
    color: var(--white);
    border-radius: 15px;
    padding: 10px;
    font-size: 20px;
    display: flex;
}

.edit_btn_cont h4{
    margin-left: 10px;
}



.farmer_dashboard_container{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 10px;
}

/* LEFT PORTION */

.farmer_profile{
    width: 100%;
    background-color: var(--bg1);
    padding: 10;
    border-radius: 10px;
}

.farmer_profile > .top{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;

    padding: 20px;
    background-color: var(--primeCol10);
    min-height: 200px;
    align-items: flex-end;
    border-radius: 15px 15px 0 0;
}

.farmer_profile > .top > .farmer_image{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: var(--bg2);
    border: 5px solid var(--primeCol);
    margin-right: 15px;
    margin-bottom: -175px;
    overflow: hidden;
}

.farmer_image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
}


.farmer_position > h3{
    display: flex;
    align-items: center;
}

.farmer_position > h3 > span{
    color: var(--primeCol);
}


.farmer_profile > .bottom{
    width: 100%;
    padding: 10px;
    margin-top: 175px;
}

.farmer_profile > .bottom > div{
    padding: 10px;
    width: 100%;
}

.farmer_profile > .bottom > div > h4{
    font-size: 16px;
}

.farmer_profile > .bottom > div > h4 > span{
    font-weight: normal;
    font-size: 18px;
}

.container_right{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 20px;
}

.farm_info{
    width: 100%;
    background-color: var(--bg1);
    padding: 20px;
    flex: 0.6;
    border-radius: 10px;
}

.farm_info > .farm_details h4{
    font-size: 20px;
    padding: 5px;
}

.farm_info .farm_details > h4 > span{
    font-weight: normal;
}

.farm_info_title{
    font-weight: normal;
    opacity: 0.5;
    padding: 20px 0;
    margin-bottom: 20px;
}


.cycle_counter_cont{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.cycle_counter_cont > h4{
    text-align: center;
    font-weight: normal;
    margin-top: 20px;
}

.cycle_counter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.counter_incre,
.counter_decre{
    width: 50px;
    padding: 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 10px;
}

.counter_incre:hover,
.counter_decre:hover{
    background-color: var(--primeCol10);
}

.counter{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--primeCol);
    display: grid;
    place-items: center;
    color: var(--white);
}

.container_right_bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 0.4;
    margin-top: 20px;
}


.container_right .wallet , 
.container_right .analytics{
    flex: 1;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--bg1);
    border-radius: 10px;
    min-height: 200px;
}

.container_right .analytics{
    margin-top: 20px;
}

.container_right .wallet:hover,
.container_right .analytics:hover{
    background-color: var(--primeCol10);
    cursor: pointer;
}

.wallet_icon,
.analytics_icon{
    font-size: 50px;
    color: var(--primeText);
    margin-bottom: 20px;
}

.container_right .wallet > h4 , 
.container_right .analytics > h4{
    font-weight: normal;
}


/* RIGHT PORTION */


@media (min-width: 740px) {

    .farmer_dashboard_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        padding: 20px 0;
    }

    .farmer_profile{
        padding: 20px;
    }

    .farmer_profile, .farm_info{
        border-radius: 15px;

    }

   .farmer_profile .bottom,
   .farm_info .farm_details{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

   }
    
    .farmer_profile > .bottom > div > h4{
        font-size: 22px;
    }

    .farmer_profile > .bottom > div > h4 > span{
        font-size: 20px;
    }

    .container_right{
        display: flex;
        flex-direction: column;
        margin-top: 0;
    }

    .container_right_bottom{
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        align-items: center;
        margin-top: 0;
    }



}