.warehouse_details{
    min-height: 100%;
    background-color: var(--bg2);
    padding: 20px;
    color: var(--text);
}




.warehouse_details_title{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    text-align: center;
    margin-top: 30px;
}
.warehouse_details_grid{
    display: grid;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    gap: 20px;
    margin-top: 30px;
}


/* CARD STYLE */

.inventory_card{
    width: 100%;
    min-height: 200px;
    border-radius: 15px;
    padding: 15px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
}

.inventory_card img{
    width: 100%;
    height: 250px;
    background-color: var(--bg2);
    border-radius: 15px;

}

.inventory_card h4{
    padding-bottom: 20px;
    font-weight: 600;
}

.inventory_card h5{
    padding: 20px 0;
    font-weight: normal;
}


.inventory_card__actions{
    width: 100%;
    padding: 10px;
    display: grid;
    place-items: center;
}

.inventory_card__actions > div{
    display: flex;
    gap: 15px;
    width: 100%;
}
.inventory_quantity{
    flex: 1;
    width: 100%;
    background-color: var(--primeCol10);
    display: grid;
    place-items: center;
}

.inventory_card_btn{
    background-color: var(--primeCol);
    color: var(--white);
    border-radius: 10px;
    height: 50px;
    width: 50px;
    display: grid;
    place-items: center;
}


@media (min-width : 768px) {
    .warehouse_details_grid{
        grid-template-columns: 1fr  1fr;
    }
}

@media (min-width : 1024px) {
    .warehouse_details_grid{
        grid-template-columns: 1fr 1fr 1fr;
    }
}