/* .app{
    overflow-x: hidden;
} */


.layout_body > div{
    min-width: 300px;
}


/* this is for the actions in every table */

.actions_cont,
.actions_cont_inner{
    width: 100%;
    
}

.actions_cont{
    margin-top: 20px;
}
.actions_cont_inner_client{
    max-width: 1200px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg1);
    border-radius: 15px;
    padding: 15px;
}
.actions_cont_inner{
    max-width: 1200px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    background-color: var(--bg1);
    border-radius: 15px;
    padding: 15px;
}

.actions_btn_cont{
    display: flex;
}

.action_btn{
    padding: 10px;
    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    font-size: 20px;
    cursor: pointer;
    color: var(--primeCol);
}

.action_btn:hover{
    background-color: var(--primeCol10);
    border-radius: 50%;
}

.table_pagination_cont,
.table_page_control{
    display: flex;
    align-items: center;
    gap: 15px;
}

.table_pagination_cont{
    padding: 0 20px;
}

.table_page_number h4{
    padding: 10px;
}

.table_page_control .control_btn{
    font-size: 25px;
    display: grid;
    place-items: center;
    padding: 10px;
    border-radius: 50%;
}

.table_page_control .control_btn:hover{
    background-color: var(--primeCol10);
    cursor: pointer;
}


.table_loading_cont{
    width: 100%;
    display: grid;
    place-items: center;
}

.table_loading_cont_inner{
    width: 150px;
    height: 5px;
    position: relative;
    background-color: var(--primeCol10);
    overflow: hidden;
}

.table_loading_indicator{
    height: 100%;
    width: 70px;
    position: absolute;
    background-color: var(--primeCol);
    -webkit-animation: load 1s infinite linear;
    animation: load 1s infinite linear;
}

@-webkit-keyframes load{
    from {
        transform: translateX(50);
    }
    to{
        transform: translateX(-50);
    }
}



@keyframes load {
    from{
       left: -60px;
       transition: linear;
    }
    to{
        left: 140px;
        transition: linear;
    }
}

