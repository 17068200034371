.loading,.loading2{
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    background: var(--bg2);
}

.loading2{
    height: calc(100vh - 50px);
}



.loading{
    width: 100% !important;
    height: 100vh ;
    max-width: 100% !important;
    display: grid;
    place-items: center;
    background-color: var(--white10);
    backdrop-filter: blur(5px);
    z-index: 200;
    position: relative;
}

.loading__box{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loading__box > h1{
    opacity: 0.5;
    color: var(--primeCol);
}

.load__indicator__box{
    width: 150px;
    height: 3px;
    overflow: hidden;
    position: relative;
    background-color: var(--white);
    margin-top: 10px;
}
.load__indicator{
    height: 3px;
    width: 50%;
    background-color: var(--primeCol);
    -webkit-animation: load 1s infinite linear;
    animation: load 1s infinite linear;
    position: absolute;
    left: -60px;
}

.btn__loading{

    width: 30px;
    height: 30px;
    margin: auto;

    border: 3px solid var(--white);
    padding: 10px;
    border-radius: 50%;
    border-bottom: none;
    animation: rotateInfinite 0.5s infinite;
    -webkit-animation:rotateInfinite 0.5s linear infinite;
}
@keyframes rotateInfinite {
    from{
        transform: rotate(0deg) ;
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes load{
    from {
        transform: translateX(50);
    }
    to{
        transform: translateX(-50);
    }
}

@keyframes load {
    from{
       left: -60px;
       transition: linear;
    }
    to{
        left: 140px;
        transition: linear;
    }
}